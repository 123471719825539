import React, { useState } from "react";
import "./ScrollToTopButton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to scroll back to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to handle scroll event and toggle visibility of button
  const handleScroll = () => {
    const scrollTop = window.pageYOffset;

    if (scrollTop > 400) {
      // Adjust this value based on your UI/UX preference
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Effect to add event listener for scroll
  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Render the button if isVisible is true
  return (
    <>
      {isVisible && (
        <button className="scroll-to-top-btn" onClick={scrollToTop}>
          Scroll to Top <FontAwesomeIcon icon={faArrowUp} />
        </button>
      )}
    </>
  );
};

export default ScrollToTopButton;
