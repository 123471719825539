import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "./Redux/Store/store.js";
import { Link } from "react-scroll";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSun, faMoon } from "@fortawesome/free-solid-svg-icons";
import "./styles.css";

const Header = () => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.theme.mode);

  return (
    <header className={`header ${mode}`}>
      {/* <nav>
        <Link className="logo" to="home" smooth={true} duration={500}>
          <img
            src="https://w7.pngwing.com/pngs/552/283/png-transparent-financial-technology-congress-insurtech-fintech-2017-warsaw-fintech-angle-text-trademark.png"
            alt="Logo"
          />
        </Link>

        <ul>
          <li>
            <Link className="nav-link" to="home" smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="about" smooth={true} duration={500}>
              About Us
            </Link>
          </li>
          <li>
            <Link
              className="nav-link"
              to="features"
              smooth={true}
              duration={500}
            >
              Features
            </Link>
          </li>
          <li>
            <Link
              className="nav-link"
              to="benefits"
              smooth={true}
              duration={500}
            >
              Benefits
            </Link>
          </li>
          <li>
            <Link
              className="nav-link"
              to="testimonials"
              smooth={true}
              duration={500}
            >
              Testimonials
            </Link>
          </li>
          <li>
            <Link
              className="nav-link"
              to="downloadapp"
              smooth={true}
              duration={500}
            >
              Download App
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="blogs" smooth={true} duration={500}>
              Blogs
            </Link>
          </li>
          <li>
            <Link className="nav-link" to="faq" smooth={true} duration={500}>
              FAQ
            </Link>
          </li>
          <li>
            <Link
              className="nav-link"
              to="contact"
              smooth={true}
              duration={500}
            >
              Contact
            </Link>
          </li>
        </ul>
        <Link to="downloadapp" smooth={true} duration={500}>
          <button className="getStarted">Get Started</button>
        </Link>
        <button
          className="toggleTheme"
          onClick={() => dispatch(toggleTheme())}
          aria-label="Toggle theme"
        >
          <FontAwesomeIcon icon={mode === "light" ? faMoon : faSun} />
        </button>
      </nav> */}
    </header>
  );
};

export default Header;
