// Footer.js
import React from "react";
import { useSelector } from "react-redux";
import "./styles.css";

const Footer = () => {
  const mode = useSelector((state) => state.theme.mode);

  return (
    <footer>{/* <p>© 2024 DhanTech Corp. All rights reserved.</p> */}</footer>
  );
};

export default Footer;
