// Content.js
import React from "react";
import { Element } from "react-scroll";
import { useSelector } from "react-redux";
import "./Content.css";
import classes from "./Content.module.css";
import Home from "./assests/Home.png";

const Content = () => {
  const mode = useSelector((state) => state.theme.mode);

  return (
    // <div className={`content ${mode}`}>
    //   <Element name="home" className="element home-section">
    //     <div className="flex-container">
    //       <div className="right">
    //         <h1>Welcome to DhanTech Corp</h1>
    //         <p>
    //           DhanTech Corp is revolutionizing the way you manage your finances
    //           with cutting-edge technology and innovative solutions. Our
    //           flagship product, the National Common Mobility Card (NCMC), is
    //           designed to provide seamless access across various transportation
    //           networks, making your commute easier and more efficient.
    //         </p>
    //       </div>
    //       <div className="left">
    //         <img
    //           src="https://paycraftsol.com/wp-content/uploads/2019/12/common-mobility-card-solutions-img02.png"
    //           alt="NCMC Card"
    //           className="content-image"
    //         />
    //       </div>
    //     </div>
    //   </Element>

    //   <Element name="about" className="element">
    //     <div className="flex-container">
    //       <div className="left">
    //         <h1>About Us</h1>
    //         <p>
    //           At DhanTech Corp, we are committed to delivering top-notch
    //           financial technology solutions. Our mission is to enhance the
    //           financial experiences of our customers by offering secure,
    //           reliable, and user-friendly products.
    //         </p>
    //       </div>
    //       <div className="right">
    //         <img
    //           src="https://cdni.iconscout.com/illustration/free/thumb/free-about-us-2061897-1740019.png?f=webp"
    //           alt="About Us"
    //           className="content-image"
    //         />
    //       </div>
    //     </div>
    //   </Element>

    //   <Element name="features" className="element">
    //     <div className="flex-container">
    //       <div className="right">
    //         <h1>Features</h1>
    //         <ul>
    //           <li>
    //             Seamless integration with multiple transportation networks
    //           </li>
    //           <li>Contactless payment technology</li>
    //           <li>Secure and encrypted transactions</li>
    //           <li>Easy recharge options through mobile app and website</li>
    //         </ul>
    //       </div>
    //       <div className="left">
    //         <img
    //           src="https://www.pngkey.com/png/full/423-4235202_some-of-the-salient-features-offered-by-this.png"
    //           alt="Features"
    //           className="content-image"
    //         />
    //       </div>
    //     </div>
    //   </Element>

    //   <Element name="benefits" className="element">
    //     <div className="flex-container">
    //       <div className="left">
    //         <h1>Benefits</h1>
    //         <ul>
    //           <li>Convenient and hassle-free travel</li>
    //           <li>Time-saving with quick transactions</li>
    //           <li>Reduced need for multiple cards for different services</li>
    //           <li>Environment-friendly by reducing paper tickets</li>
    //         </ul>
    //       </div>
    //       <div className="right">
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/6213/6213255.png"
    //           alt="Benefits"
    //           className="content-image"
    //         />
    //       </div>
    //     </div>
    //   </Element>

    //   <Element name="testimonials" className="element">
    //     <div className="flex-container">
    //       <div className="right">
    //         <h1>Testimonials</h1>
    //         <blockquote>
    //           <p>
    //             "The NCMC has made my daily commute so much easier. I no longer
    //             need to carry cash or multiple cards!"
    //           </p>
    //           <footer>- Jane Doe, Satisfied Customer</footer>
    //         </blockquote>
    //         <blockquote>
    //           <p>
    //             "DhanTech Corp's innovative solutions have transformed the way I
    //             travel. Highly recommend their services!"
    //           </p>
    //           <footer>- John Smith, Frequent Traveler</footer>
    //         </blockquote>
    //       </div>
    //       <div className="left">
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/1313/1313243.png"
    //           alt="Testimonials"
    //           className="content-image"
    //         />
    //       </div>
    //     </div>
    //   </Element>

    //   <Element name="downloadapp" className="element">
    //     <div className="flex-container">
    //       <div className="left">
    //         <h1>Download Our App</h1>
    //         <p>
    //           Get the FinTech Corp app to manage your NCMC, recharge your card,
    //           and access exclusive offers. Available on both iOS and Android.
    //         </p>
    //         <div className="download-links">
    //           <a href="https://www.apple.com/app-store/">
    //             <img
    //               src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
    //               alt="Download on the App Store"
    //               className="app-store-badge"
    //             />
    //           </a>
    //           <a href="https://play.google.com/store">
    //             <img
    //               src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
    //               alt="Get it on Google Play"
    //               className="app-store-badge"
    //             />
    //           </a>
    //         </div>
    //       </div>
    //       <div className="right">
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/2586/2586738.png"
    //           alt="Download Our App"
    //           className="content-image"
    //         />
    //       </div>
    //     </div>
    //   </Element>

    //   <Element name="blogs" className="element">
    //     <div className="flex-container">
    //       <div className="right">
    //         <h1>Our Blog</h1>
    //         <div className="blog-post">
    //           <h2>The Future of DhanTech</h2>
    //           <p>
    //             Discover the latest trends and advancements in the financial
    //             technology sector...
    //           </p>
    //           <a href="#">Read more</a>
    //         </div>
    //         <div className="blog-post">
    //           <h2>How NCMC is Changing the Commute</h2>
    //           <p>
    //             Learn how the National Common Mobility Card is revolutionizing
    //             transportation...
    //           </p>
    //           <a href="#">Read more</a>
    //         </div>
    //       </div>
    //       <div className="left">
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/6114/6114045.png"
    //           alt="Our Blog"
    //           className="content-image"
    //         />
    //       </div>
    //     </div>
    //   </Element>

    //   <Element name="faq" className="element">
    //     <div className="flex-container">
    //       <div className="left">
    //         <h1>FAQ</h1>
    //         <div className="faq-item">
    //           <h2>What is the National Common Mobility Card (NCMC)?</h2>
    //           <p>
    //             The NCMC is a contactless smart card that can be used for
    //             various forms of transportation across the country, including
    //             buses, trains, and metros. It aims to provide a seamless and
    //             hassle-free travel experience.
    //           </p>
    //         </div>
    //         <div className="faq-item">
    //           <h2>How can I get an NCMC?</h2>
    //           <p>
    //             You can apply for an NCMC through our website or at authorized
    //             retail outlets. The card will be linked to your bank account for
    //             convenient recharges and payments.
    //           </p>
    //         </div>
    //         <div className="faq-item">
    //           <h2>Is the NCMC secure?</h2>
    //           <p>
    //             Yes, the NCMC uses advanced encryption technology to ensure the
    //             security of your transactions. Your personal and financial
    //             information is protected at all times.
    //           </p>
    //         </div>
    //       </div>
    //       <div className="right">
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/4403/4403555.png"
    //           alt="FAQ"
    //           className="content-image"
    //         />
    //       </div>
    //     </div>
    //   </Element>

    //   <Element name="contact" className="element">
    //     <div className="flex-container">
    //       <div className="right ">
    //         <h1>Contact Us</h1>
    //         <p>
    //           If you have any questions or need assistance, please feel free to
    //           reach out to us. You can contact us via email at
    //           support@dhantechcorp.com or call us at 1-800-123-4567.
    //         </p>
    //       </div>
    //       <div className="left">
    //         <img
    //           src="https://cdn-icons-png.flaticon.com/512/9239/9239182.png"
    //           alt="Contact Us"
    //           className="content-image"
    //         />
    //       </div>
    //     </div>
    //   </Element>
    // </div>

    <div className={classes.main}>
      <img
        alt="SignUpPic"
        src={Home}
        // className={classes.formMainPic}
      />
    </div>
  );
};

export default Content;
